<template>
  <div class="main">
    <div class="container-image">
      <div class="container-image">
        <img
          src="../../assets/images/bismillah-image.jpg"
          class="image-bismillah"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.image-bismillah {
  width: 45%;
}
.container-image {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
}
</style>
